import { isRejectedWithValue } from "@reduxjs/toolkit";
import { removeToken } from "../services/localStorageService";
import { logout } from "../features/authSlice";

const sessionMiddleware = (store) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    const { status } = action.payload || {};

    const dispatch = store.dispatch;

    if (status === 401) {
      console.log("Unauthorized, logging out...");

      removeToken();
      dispatch(logout());

      window.location.href = "/account"; 
    }
  }

  return next(action);
};

export default sessionMiddleware;
