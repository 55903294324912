function UserIcon() {
  return (
    <>
      <svg
        width="17"
        height="20"
        viewBox="0 0 17 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.5288 9.27363C12.8144 8.34031 13.6515 6.82984 13.6515 5.1282C13.6515 2.30051 11.3406 0 8.5 0C5.65945 0 3.34848 2.30051 3.34848 5.1282C3.34848 6.82984 4.18559 8.34031 5.47117 9.27363C2.27546 10.491 0 13.5754 0 17.1795C0 18.7347 1.27103 20 2.83333 20H14.1667C15.729 20 17 18.7347 17 17.1795C17 13.5754 14.7245 10.491 11.5288 9.27363ZM4.89395 5.1282C4.89395 3.14883 6.51162 1.53848 8.5 1.53848C10.4884 1.53848 12.1061 3.14883 12.1061 5.1282C12.1061 7.10758 10.4884 8.71797 8.5 8.71797C6.51162 8.71797 4.89395 7.10758 4.89395 5.1282ZM14.1667 18.4615H2.83333C2.1232 18.4615 1.54547 17.8864 1.54547 17.1795C1.54547 13.362 4.66522 10.2564 8.50004 10.2564C12.3349 10.2564 15.4546 13.362 15.4546 17.1795C15.4546 17.8864 14.8768 18.4615 14.1667 18.4615Z"
          fill="#15B8FB"
        />
      </svg>
    </>
  );
}

function NotificationIcon() {
  return (
    <>
      <svg
        width="34"
        height="34"
        viewBox="0 0 34 34"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <circle cx="17" cy="17" r="17" fill="#ECECEC" />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M17.2131 7.54517C13.0003 7.54517 11.1699 11.3598 11.1699 13.8815C11.1699 15.7662 11.4431 15.2116 10.3999 17.5107C9.12594 20.787 14.2489 22.1261 17.2131 22.1261C20.1763 22.1261 25.2993 20.787 24.0262 17.5107C22.983 15.2116 23.2562 15.7662 23.2562 13.8815C23.2562 11.3598 21.4249 7.54517 17.2131 7.54517Z"
          stroke="#121212"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M19.4078 24.644C18.1781 26.0175 16.2599 26.0338 15.0186 24.644"
          stroke="#121212"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <circle cx="29" cy="5" r="3.5" fill="#FF0000" stroke="white" />
      </svg>
    </>
  );
}
export { UserIcon, NotificationIcon };
