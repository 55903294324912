import React, { useState } from "react";
import DashboardLayout from "../../Components/Layouts/DashBoardLayout";
import { dispachterdata } from "../Dashboard";
import { CardTitle } from "../../Components/CustomComponents";
import DispatcherCard from "../../Components/Cards/DispatcherCard";
import { Col, Row } from "react-bootstrap";
import NomalDispatcherModal from "../../Components/Modals/NormalModal";
import { useSelector } from "react-redux";
import socketIo from "../../config/Socket"
import { SuccessToast } from "../../Hooks/useToast";

const Normal = () => {
  const [normalModal, setNormalModal] = useState(false);
  const [modalData, setModalData] = useState({})
  const [modaluserId ,setMdoalUserId ] = useState('') ;
  const data = useSelector((state) => state.officerInfo);
  const filteredNormalDispatcher = data?.data?.filter(
    (item) => item.heart_rate < 85 && item.heart_rate > 40
  ) || [];

  const handleModalClose = () => {
    setNormalModal((prev) => !prev);
    leaveRoom(modaluserId);
  };

  const handleResponse = (data, successMessage, errorMessage) => {
    setModalData(data);
  };

  const joinRoom = (userId) => {
    socketIo.emit("_joinUserRoom", userId);
    socketIo.emit("_getOfficer", userId);
    socketIo.on("getOfficer_", (data) =>
      handleResponse(data, "", "Error getting officer details")
    );
  };

  const leaveRoom = (userId) => {
    socketIo.emit("_leaveUserRoom", userId);
  };

  return (
    <DashboardLayout>
      <section>
        <CardTitle text="Normal" classes="text-black fs-5 fw-bold mb-2" />
        <Row className={`mb-5`}>
          {filteredNormalDispatcher.length > 0 ? (
            filteredNormalDispatcher.map((item) => (
              <Col
                className={`mt-2 mb-2 curs`}
                lg={4}
                md={4}
                sm={6}
                key={item.id}
                onClick={() => {setNormalModal(true); joinRoom(item.slug); setMdoalUserId(item.slug)}}
              >
                <DispatcherCard data={item} />
              </Col>
            ))
          ) : (
            <p>Data Not Available</p>
          )}
        </Row>
      </section>
      {normalModal ? (
        <NomalDispatcherModal handleClose={handleModalClose} data={modalData} />
      ) : null}
    </DashboardLayout>
  );
};

export default Normal;
