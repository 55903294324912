import styles from './index.module.scss';

import _ from 'lodash';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { FaMicrophone, FaMicrophoneSlash } from "react-icons/fa6";
import { IoCallOutline } from "react-icons/io5";
import { useLocation, useNavigate } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { useCreateTokenMutation } from '../../Data/services/agoraApi';
import socket from "../../config/Socket";

import {
    LocalUser,
    RemoteUser,
    useIsConnected,
    useJoin,
    useLocalMicrophoneTrack,
    usePublish,
    useRemoteUsers
} from "agora-rtc-react";
import { useSocket } from '../../context/SocketContext';
import { useVoiceCall } from '../../context/VoiceCallContext';
import CallPopup from '../../Components/Modals/CallPopup';
import { WEB_ROUTES } from '../../constants';

const VoiceCall = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const { state } = location;

    const timer = useRef()

    const { handleCallAccept, handleOnCallReject, handleEndCall: updateCallStatus, incoming_calls, removeIncommingCall } = useVoiceCall()
    const { isConnected: isSocketConnected } = useSocket()
    const [createToken] = useCreateTokenMutation();

    const isInitiateCall = useMemo(() => !!state?.isInitiateCall, [])


    const [token, setToken] = useState("");
    const [channel, setChannel] = useState(state?.channel_name || '');
    const [isRinging, setIsRinging] = useState(false);
    const [isTokenError, setIsTokenError] = useState(false);
    const [isCallAccepted, setIsCallAccepted] = useState(!isInitiateCall || false);
    const [isCallEnded, setIsCallEnded] = useState(false);
    const [seconds, setSeconds] = useState(0);
    const [minutes, setMinutes] = useState(0);


    useJoin({ appid: process.env.REACT_APP_AGORA_APP_ID, channel: channel, token: token }, !!token);
    const isConnected = useIsConnected();

    const [micOn, setMic] = useState(true);
    const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
    usePublish([localMicrophoneTrack]);
    const remoteUsers = useRemoteUsers();



    useEffect(() => {
        window.addEventListener('beforeunload', clearStateOnRefresh);
        return () => window.removeEventListener('beforeunload', clearStateOnRefresh);
    }, [location.pathname, navigate]);


    useEffect(() => {
        if (isSocketConnected) {
            socket.on('_callCancelled', (data) => {
                handleEndCall()
            })
        }

        return () => {
            if (isSocketConnected) {
                socket.dispose('_callCancelled')
            }
        }

    }, [])

    useEffect(() => {
        if (!isInitiateCall) return;

        if (!isCallAccepted) {
            timer.current = setTimeout(() => {
                navigate(-1)
            }, 1000 * 60)
        }
        else {
            clearTimeout(timer.current)
        }

    }, [isCallAccepted])


    useEffect(() => {
        if (!isConnected) return;

        const getInterval = () => {
            return setInterval(() => {
                setSeconds((prevSeconds) => {
                    if (prevSeconds === 59) {
                        setMinutes((prevMinutes) => prevMinutes + 1);
                        return 0;
                    }
                    return prevSeconds + 1;
                })
            }, 1000);
        }


        let interval;
        if (isInitiateCall) {
            if (isCallAccepted) {
                interval = getInterval()
            }
        }
        else {
            handleCallAccept(state?.channel_name, state?.from_user_slug);
            interval = getInterval()
        }


        return () => {
            if (isConnected) {
                clearInterval(interval);
            }
        }

    }, [isConnected])


    useEffect(() => {
        const initiate = async () => {
            if (_.isEmpty(state)) return navigate(-1, { replace: true, flushSync: true })
            let channel_name;
            let is_join;
            let other_user_slug = '';
            if (isInitiateCall) {
                channel_name = uuidv4()
                is_join = 0
                other_user_slug = state?.from_user_slug
            }
            else {
                // if (!state?.token) return navigate(-1, { replace: true, flushSync: true })
                channel_name = state?.channel_name;
                is_join = 1;
            }

            try {
                const payload = {}
                payload.is_join = is_join;
                payload.channel_name = channel_name;
                payload.other_user_slug = other_user_slug;
                payload.is_video = 0;

                const tokenResponse = await createToken(payload).unwrap()

                setChannel(channel_name);
                setToken(tokenResponse?.data?.token);
                setIsRinging(true);


            }
            catch (err) {
                setIsTokenError(true)
                setTimeout(() => navigate(-1), 1500)
            }
        }
        initiate()
    }, [])


    function clearStateOnRefresh() {

        if (isInitiateCall) {
            //TODO:logic If I initiate call
        }
        else {
            if (isConnected) {
                socket.emit("_rejectCall", {
                    channel_name: state?.channel_name,
                    other_user_slug: state?.from_user_slug
                })
            }
            else {
                socket.emit('_cancelCall', {
                    channel_name: state?.channel_name,
                    other_user_slug: state?.from_user_slug
                })
            }
        }
        navigate(location.pathname, { replace: true, state: null });
    };

    const handleCancelCall = () => {
        socket.emit('_cancelCall', {
            channel_name: state?.channel_name,
            other_user_slug: state?.from_user_slug
        })
        handleEndCall()
    }

    function handleEndCall() {
        setIsCallEnded(true)
        updateCallStatus()
        setTimeout(() => {
            navigate(-1, { replace: true })
        }, 1000)

    }


    const handleAcceptCall = (data) => {
        socket.emit('_cancelCall', {
            channel_name: state?.channel_name,
            other_user_slug: state?.from_user_slug
        })
        removeIncommingCall(data?.channel_name, { activeChannel: data?.channel_name })
        navigate(`/${WEB_ROUTES.JOIN_CALL}`, {
            replace: true,
            state: data
        })

    }


    if (_.isEmpty(state)) {
        return <div className='w-100 h-100dvh d-flex justify-content-center align-items-center'>
            <Spinner />
        </div>
    }


    return <div className={styles.VoiceCall}>
        {incoming_calls?.map((item, index) => {
            return <CallPopup
                key={index}
                data={item}
                onReject={handleOnCallReject}
                style={{ top: `${index * 80}px` }}
                onAccept={() => handleAcceptCall(item)}
            />
        })}
        <main className={styles.main}>
            <div className={styles.participants}>
                <LocalUser
                    audioTrack={localMicrophoneTrack}
                    micOn={micOn}
                    className={styles.localUser}
                >
                    <div className={styles.participant}>
                        <div className={styles.avatar}>{state?.from_user_name[0] || '-'}</div>
                        <div className={'mt-2 mb-4'}>
                            <div className={styles.name}>{state?.from_user_name}</div>
                            <div className={styles.audioStatus}>
                                {
                                    isTokenError ? "Network error" :
                                        isCallEnded ? "Call Ended" :
                                            isInitiateCall ?
                                                (isRinging && isConnected) ? "Ringing..." : "Calling..."
                                                :
                                                isConnected ? `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}` : "Waiting for connection..."
                                }
                            </div>
                        </div>
                    </div>
                </LocalUser>
            </div>
            {remoteUsers.map((user) => <RemoteUser user={user} />)}
        </main >
        <div className={styles.controlBar}>
            <button onClick={() => setMic(prev => !prev)}>
                {!!micOn ? <FaMicrophone size={22} /> : <FaMicrophoneSlash size={22} />}
            </button>
            <button className={styles.endCall} onClick={handleCancelCall}><IoCallOutline size={22} /></button>
        </div>
    </div >
}


export default VoiceCall;
