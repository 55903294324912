import React, { useState } from "react";

import './MapFixed.css';

import { useJsApiLoader } from "@react-google-maps/api";
import { Spinner } from "react-bootstrap";
import Map from "./Map.js";

function MapFixed({ location }) {
    const [libraries] = useState(['places']);
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
        libraries: libraries
    })
    return (
        <div className='mapFixed'>
            {!isLoaded ?
                <Spinner /> :
                <Map location={location} setLatLng={() => { }} option={{ gestureHandling: 'none' }} className="googleMap" />
            }
        </div>
    )
}

export default React.memo(MapFixed)