import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentChat: null,
  chats: [
    {
      id: 1,
      name: "Leo Lubin",
      lastMessage: "How are you doing?",
      newMessageCount: 2,
      messages: [{id: 0 , text: "Hello", sender: "" }, { id: 1 , text: "Hello", sender: "me" }, { id: 3, text: "Hello", sender: "" },{id: 5 , text: "Hello", sender: "" }, { id: 6 ,text: "Hello", sender: "me" },{ text: "Hello", sender: "" }],
    },
    {
      id: 2,
      name: "Cloudy Friends",
      lastMessage: "Yes, that's true.",
      newMessageCount: 1,
      messages: [{ text: "Yes, that's true.", sender: "" }],
    },
    {
      id: 3,
      name: "Randy",
      lastMessage: "Can’t talk right now",
      newMessageCount: 0,
      messages: [{ text: "Hello", sender: "" }, { text: "Hello", sender: "me" }],
    },
    {
      id: 4,
      name: "Randy",
      lastMessage: "Can’t talk right now",
      newMessageCount: 0,
      messages: [{ text: "Hello", sender: "" }, { text: "Hello", sender: "me" }],
    },
    {
      id: 5,
      name: "Randy",
      lastMessage: "Can’t talk right now",
      newMessageCount: 0,
      messages: [{ text: "Hello", sender: "" }, { text: "Hello", sender: "me" }],
    },
    {
      id: 6,
      name: "Randy",
      lastMessage: "Can’t talk right now",
      newMessageCount: 0,
      messages: [{id: 0 , text: "Hello", sender: "" }, { id: 1 ,text: "Hello", sender: "me" }],
    },
  ],
};

export const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    selectChat: (state, action) => {
      state.currentChat = state.chats.find(chat => chat.id === action.payload);
      console.log('Selected Chat: ', state.currentChat);
    },
    // sendMessage: (state, action) => {
    //   if (state.currentChat) {
    //     const chatIndex = state.chats.findIndex(chat => chat.id === state.currentChat.id);
    //     state.chats[chatIndex].messages.push({ text: action.payload, sender: 'me' });
    //     state.chats[chatIndex].lastMessage = action.payload;
    //   }
    // },
    sendMessage: (state, action) => {
      const { chatId, message, file, image } = action.payload;
      const chatIndex = state.chats.findIndex(chat => chat.id === chatId);

      if (chatIndex !== -1) {
        state.chats[chatIndex].messages.push({
          text: message,
          sender: 'me',
          file: file,
          image: image
        });
        state.chats[chatIndex].lastMessage = message;
      }
    },
  },
});

export const { selectChat, sendMessage } = chatSlice.actions;
export const chatReducer = chatSlice.reducer;
