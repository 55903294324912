import React from "react";
import { Navigate } from "react-router-dom";
import { WEB_ROUTES } from "../../constants";
import { baseRoute } from "../../Helper";
import { useSelector } from "react-redux";
import { Spinner } from "react-bootstrap";
import _ from "lodash";


export default function PublicRoute({ children }) {
  const { isLoggedIn } = useSelector(state => state.authInfo);

  return (
    _.isUndefined(isLoggedIn) ? <div className="w-100 h-100dvh d-flex align-items-center justify-content-center"><Spinner /></div> :
      isLoggedIn ? <Navigate to={baseRoute(WEB_ROUTES.DASHBOARD)} replace={true} /> : children

  );
}