import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";

/** Import Assets */
import styles from "./styles/SideListView.module.scss";

/** Import Custom Components */
import { WEB_ROUTES } from "../../constants";
import Navbar from "./Navbar";
import { removeToken } from "../../Data/services/localStorageService";
import Toast from "../../Hooks/useToast";
import LogoutModal from "../Modals/LogoutModal";
import { SubTitle, CardTitle, ImageWithPlaceholder } from "../CustomComponents";
import { GiHamburgerMenu } from "react-icons/gi";
import { RxDoubleArrowLeft } from "react-icons/rx";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaWifi } from "react-icons/fa";
import { dispachterdata } from "../../Pages/Dashboard";
import profile from "../../assets/images/dispatcher_profile.png";
import closer from "../../assets/images/closer.svg";
import { useSelector } from "react-redux";

export default function SideListView({ handleToggle, collapsed }) {
  const [vitalModal, setVitalModal] = useState(false);
  const [normalModal, setNormalModal] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [expandedItems, setExpandedItems] = useState({});
  const data = useSelector((state) => state.officerInfo);

  const filteredEmergencyDispatcher =
    data?.data?.filter((item) => item.heart_rate >= 100) || [];
  const filteredAlertDispatcher =
    data?.data?.filter(
      (item) => item.heart_rate > 85 && item.heart_rate < 100
    ) || [];
  const filteredNormalDispatcher =
    data?.data?.filter(
      (item) => item.heart_rate < 85 && item.heart_rate > 40
    ) || [];

  const handleModalClose = () => {
    setVitalModal(false);
    setNormalModal(false);
    setAlertModal(false);
  };

  const toggleAccordion = (index) => {
    setExpandedItems((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };

  const items = [
    {
      label: "Emergency",
      component: (
        <VitalAccordance
          setVitalModal={setVitalModal}
          filteredEmergencyDispatcher={filteredEmergencyDispatcher}
        />
      ),
    },
    {
      label: "Security Check",
      component: (
        <SecurityAccordance
          setAlertModal={setAlertModal}
          filteredAlertDispatcher={filteredAlertDispatcher}
        />
      ),
    },
    {
      label: "Normal",
      component: (
        <NormalAccordance
          setNormalModal={setNormalModal}
          filteredNormalDispatcher={filteredNormalDispatcher}
        />
      ),
    },
  ];

  return (
    <div className={`${styles.sideview} d-none d-lg-block`}>
      <div className={styles.sideitem}>
        <p
          onClick={() => handleToggle((prev) => !prev)}
          className={`mb-3 fs-4 pointer`}
        >
          {collapsed ? <img src={closer} /> : <GiHamburgerMenu />}
        </p>
        <div className={styles.titleContainer}>
          <SubTitle text={"Officers List"} />
        </div>
      </div>
      <div className={styles.dispatchertype}>
        {items.map(({ label, component }, index) => (
          <div key={index}>
            <div className={`${styles.item}`}>
              <p>{label}</p>
              <p
                style={{ fontSize: "20px", display: "flex", cursor: "pointer" }}
                onClick={() => toggleAccordion(index)}
              >
                {expandedItems[index] ? <IoIosArrowUp /> : <IoIosArrowDown />}
              </p>
            </div>
            {expandedItems[index] && (
              <div className={`${styles.accordanceItem}`}>{component}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

const DispatcherList = ({ data, active }) => {
  const { id } = useParams();
  const paramid = id;
  function getBeatColor(beatCount) {
    if (beatCount >= 95 && beatCount <= 240) {
      return "#FF0000";
    } else if (beatCount >= 85 && beatCount < 95) {
      return "#FE740B";
    } else if (beatCount >= 40 && beatCount < 85) {
      return "#23AD23";
    }
    return "#000";
  }
  console.log(active);

  return (
    <Link to={`${process.env.REACT_APP_DOMAIN_DIR}/booking-history/${data.id}`}>
      <div className={styles.list}>
        <div className={`${styles.iconCard}`}>
          <ImageWithPlaceholder
            src={data.image_url}
            placeholder={"/images/user-placeholder.jpeg"}
            alt="Officer"
            className={styles.profileImage}
          />
        </div>
        <div className="w-50 d-flex align-items-center gap-2 text-nowrap text-truncate text-capitalize">
          <CardTitle
            text={data?.name}
            classes={`text-black fw-bold text-right ${styles.cardText}`}
          />
          {data?.id === Number(paramid) && (
            <div
              className={styles.dot}
              style={{ background: getBeatColor(data?.heart_rate) }}
            ></div>
          )}
        </div>
        <p
          className="fs-5 fw-bold m-0 w-25 text-right"
          style={{ color: getBeatColor(data?.heart_rate) }}
        >
          {data.heart_rate}
          <span className={`${styles.beatCount} text-black`}> BMP</span>
        </p>
        <FaWifi className="fiwi-icon fs-6" color="00FF00" />
      </div>
    </Link>
  );
};

const SecurityAccordance = ({
  setAlertModal,
  alertModal,
  filteredAlertDispatcher,
}) => (
  <>
    {filteredAlertDispatcher?.length > 0 &&
      filteredAlertDispatcher?.map((item) => (
        <div key={item.id} onClick={() => setAlertModal(true)}>
          <DispatcherList data={item} active={alertModal} />
        </div>
      ))}
  </>
);

const NormalAccordance = ({
  setNormalModal,
  normalModal,
  filteredNormalDispatcher,
}) => (
  <>
    {filteredNormalDispatcher?.length > 0 &&
      filteredNormalDispatcher?.map((item) => (
        <div key={item.id} onClick={() => setNormalModal(true)}>
          <DispatcherList data={item} active={normalModal} />
        </div>
      ))}
  </>
);

const VitalAccordance = ({
  setVitalModal,
  vitalModal,
  filteredEmergencyDispatcher,
}) => (
  <>
    {filteredEmergencyDispatcher?.length > 0 &&
      filteredEmergencyDispatcher?.map((item) => (
        <div key={item.id} onClick={() => setVitalModal(true)}>
          <DispatcherList data={item} active={vitalModal} />
        </div>
      ))}
  </>
);
