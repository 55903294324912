import styles from './styles/CallPopup.module.scss';

import { IoCallOutline } from "react-icons/io5";
import { MdOutlineCallEnd } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { WEB_ROUTES } from '../../constants';
import { useVoiceCall } from '../../context/VoiceCallContext';
import _ from 'lodash';


export default function CallPopup({ data = {}, style = {}, onReject = () => { }, onAccept }) {
    const navigate = useNavigate();
    const { removeIncommingCall } = useVoiceCall()

    const handleAccept = () => {
        if (_.isFunction(onAccept)) {
            onAccept()
        }
        else {
            removeIncommingCall(data?.channel_name, { onCall: true, activeChannel: data?.channel_name })
            navigate(`/${WEB_ROUTES.VOICE_CALL}`, {
                state: data
            })
        }

    }

    return <div className={styles.callBar} style={style}>
        {/* Caller Info */}
        <div className={styles.callBar__info}>
            <div className={styles.avatar}>U</div>
            <div className={styles.details}>
                <span className={`${styles.callerName} lc-1`}>{data?.from_user_name || 'Unknown Caller'}</span>
                <span className={styles.status}>Incoming Call...</span>
            </div>
        </div>

        {/* Actions */}
        <div className={styles.callBar__actions}>
            <button
                className={`${styles.button} ${styles.buttonAccept}`}
                onClick={handleAccept}
            >
                <IoCallOutline />
            </button>
            <button
                className={`${styles.button} ${styles.buttonReject}`}
                onClick={() => onReject(data?.channel_name, data?.from_user_slug)}
            >
                <MdOutlineCallEnd />
            </button>
        </div >
    </div >
}


