"use client";

import EmojiPicker from "emoji-picker-react";
import React, { useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { MdArrowBackIosNew } from "react-icons/md";
import { GrAttachment } from "react-icons/gr";
import { IoCamera } from "react-icons/io5";
import { BsSearch, BsSend } from "react-icons/bs";
import { formatTime } from "../../Helper/index.js";
import { TbMessageCircleFilled } from "react-icons/tb";
import { SocketProvider } from ".././../context/SocketContext";
import socketIo from ".././../config/Socket";
import { useSelector } from "react-redux";
import DeleteModal from "../../Components/Modals/DeleteModal.jsx";
import DeleteChatModal from "../../Components/Modals/DeleteChatModal.jsx";
// import { useAddMediaMutation } from "../../redux/Query/media";
import { AiFillMessage } from "react-icons/ai";
import { ImageWithPlaceholder } from "../../Components/CustomComponents";
import ModalContainer from "../../Components/ModalContainer/index.js";
import { useGetAllOfficersQuery } from "../../Data/services/officerApi.js";
import { SuccessToast } from "../../Hooks/useToast.js";
import { useNavigate } from "react-router-dom";
import styles from "./styles/Chat.module.scss";
import { useAddMediaMutation } from "../../Data/services/notificationApi.js";

const Chat = () => {
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [userData, setUserData] = useState(null);
  const [show, setShow] = useState(true);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [searchUsers, setSearchUsers] = useState([]);
  const [search, setSearch] = useState("");
  const [chatThreads, setChatThreads] = useState([]);
  const [threads, setThreads] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [showUnread, setShowUnread] = useState(true);
  const [pagination, setPagination] = useState(null);
  const [chatThreadHistory, setChatThreadHistory] = useState({});
  const [message, setMessage] = useState("");
  const [uploadedImages, setUploadedImages] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [messageSlug, setMessageSlug] = useState("");
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteThreadConfirm, setDeleteThreadConfirm] = useState(false);
  const [deleteThreadLoading, setDeleteThreadLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const user_id = useSelector((state) => state.officerInfo?.officerSlug);
  const login_User = useSelector((state) => state.authInfo?.user?.slug);
  const [addMedia] = useAddMediaMutation();
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const {
    data: getUser,
    error,
    isSuccess,
    isLoading: isLoadingGetUsers,
    isFetching,
    refetch,
  } = useGetAllOfficersQuery(
    {
      search: search,
      limit: pageSize,
      page: currentPage,
    },
    { refetchOnMountOrArgChange: true }
  );
  console.log(getUser);
  const route = useNavigate();
  const chatContainerRef = useRef(null);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: {},
  });

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  const imageSend = async () => {
    try {
      const formData = new FormData();
      uploadedImages.forEach((file) => {
        formData.append("file", file);
        formData.append("path", "user");
      });

      const response = await addMedia(formData);

      if (response?.data?.code === 200) {
        console.log(response.data?.data[0])
        return response.data?.data[0];
      } else {
        console.error("Image upload failed or no data returned.");
      }
    } catch (error) {
      console.error("Error uploading images:", error);
    }

    return "";
  };

  const onSubmit = async (data) => {
    console.log("submit called");
    try {
      console.log(uploadedImages.length > 0);
      setSubmitLoading(true);

      const messageData = {
        message: message?.trim() || "",
      };

      if (uploadedImages.length > 0) {
        try {
          let file = await imageSend();
          console.log(file);
          if (file) {
            messageData.message_type = "FILE";
            messageData.file_name = uploadedImages[0]?.name;
            messageData.file_url = file;

            if (chatThreadHistory?.chat_room_slug) {
              messageData.chat_room_slug = chatThreadHistory.chat_room_slug;
              socketIo.emit("_sendMessage", messageData);
            } else if (chatThreadHistory?.slug) {
              messageData.target_user_slug =
                chatThreadHistory.slug || chatThreadHistory.user_slug;
              socketIo.emit("_sendMessage", messageData);
            }
          } else {
            throw new Error("File upload failed");
          }
        } catch (imageError) {
          console.error("Error uploading image:", imageError);
          setSubmitLoading(false);
          return;
        }
      } else {
        messageData.message_type = "TEXT";
        if (chatThreadHistory?.chat_room_slug) {
          messageData.chat_room_slug = chatThreadHistory.chat_room_slug;
          socketIo.emit("_sendMessage", messageData);
        } else if (chatThreadHistory?.slug) {
          messageData.target_user_slug =
            chatThreadHistory.slug || chatThreadHistory.user_slug;
          socketIo.emit("_sendMessage", messageData);
        }
      }

      socketIo.on("receivedMessage_", (res) => {
        if (res?.code === 200) {
          getLoadChatHIstory(res?.data?.chat_room_slug);
        }
        setSubmitLoading(false);
        socketIo.dispose("receivedMessage_");
      });

      setMessage("");
      setUploadedImages([]);
      setShowEmojiPicker(false);
      const fileInput = document.getElementById("imageUpload");
      fileInput.value = "";
    } catch (error) {
      console.error("Error sending message:", error);
      setSubmitLoading(false);
    }
  };

  const getChatHistory = (threadId) => {
    const handleLoadChatHistory = (history) => {
      setChatThreadHistory((prev) => ({
        ...prev,
        data:
          page === 1
            ? history.data
            : prev?.data?.length > 0 && [...history.data, ...prev?.data],
      }));
      setPagination(history?.links);
    };

    socketIo.on("loadChatHistory_", handleLoadChatHistory);

    return () => {
      socketIo.off("loadChatHistory_", handleLoadChatHistory);
    };
  };

  const getChatThreads = () => {
    socketIo.emit("_getChatThreads");
  };

  const getLoadChatHIstory = (chat_room_slug) => {
    socketIo.emit("_loadChatHistory", {
      chat_room_slug: chat_room_slug,
      page: 1,
      limit: 100,
    });
    const handleLoadChatHistory = (history) => {
      console.log("history", history);
      setChatThreadHistory((prev) => ({
        ...prev,
        chat_room_slug: history?.chat_room_slug,
        data:
          page === 1
            ? history.data
            : prev?.data?.length > 0 && [...history.data, ...prev?.data],
      }));
      setPagination(history?.links);
    };

    socketIo.on("loadChatHistory_", handleLoadChatHistory);

    return () => {
      socketIo.dispose("loadChatHistory_", handleLoadChatHistory);
    };
  };

  const joinRoom = (chat_room_slug) => {
    console.log(chat_room_slug);
    const handleNewRoom = (data) => {
      getLoadChatHIstory(chat_room_slug);
    };

    socketIo.emit("_joinRoom", chat_room_slug);

    socketIo.on("room_", handleNewRoom);

    return () => {
      socketIo.dispose("room_", handleNewRoom);
    };
  };

  const getFindOrCreateRoom = (target_user_slug) => {
    const handleFindOrCreateRoom = (data) => {
      if (data) {
        joinRoom(data?.data?.chat_room_slug);
        getLoadChatHIstory(data?.data?.chat_room_slug);
      }
    };

    const handleNewRoom = (data) => {};

    socketIo.emit("_findOrCreateRoom", { target_user_slug });

    socketIo.on("findOrCreateRoom_", handleFindOrCreateRoom);
    socketIo.on("newRoom_", handleNewRoom);

    return () => {
      socketIo.off("findOrCreateRoom_", handleFindOrCreateRoom);
      socketIo.off("newRoom_", handleNewRoom);
    };
  };

  const handleImageUpload = (event) => {
    const files = Array.from(event.target.files);
    console.log(files);
    setUploadedImages((prevImages) => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    const fileInput = document.getElementById("imageUpload");
    fileInput.value = "";
    setUploadedImages((prevImages) => {
      return prevImages.filter((_, i) => i !== index);
    });
  };

    useEffect(() => {
      getFindOrCreateRoom(user_id?.slug);
      setChatThreadHistory({
        chat_room_slug: user_id?.chat_room_slug,
        slug: user_id?.user_slug || user_id?.slug,
        room_name: user_id?.room_name,
        firstname: user_id?.firstname,
        lastname: user_id?.lastname,
        name: user_id?.name,
        username: user_id?.username,
        image_url:user_id?.image_url,
      });
    }, [user_id?.slug]);

  useEffect(() => {
    getChatThreads();
  }, []);

  useEffect(() => {
    // socketIo.emit("_getChatThreads");
    setLoading(true);
    const handleChatThreads = (response) => {
      if (response.code === 200) {
        setChatThreads(response.data);
        setThreads(response.data);
        setLoading(false);
      }
    };
    socketIo.on("getChatThreads_", handleChatThreads);

    return () => {
      socketIo.dispose("getChatThreads_", handleChatThreads);
    };
  }, [socketIo]);

  console.log(threads);

  useEffect(() => {
    const handleLoadChatHistory = (history) => {
      console.log("history", history);
      setChatThreadHistory((prev) => ({
        ...prev,
        data:
          page === 1
            ? history.data
            : prev?.data?.length > 0 && [...history.data, ...prev?.data],
      }));
      setPagination(history?.links);
    };

    socketIo.on("loadChatHistory_", handleLoadChatHistory);

    return () => {
      socketIo.dispose("loadChatHistory_", handleLoadChatHistory);
    };
  }, [socketIo]);

  // MARK: Received Message
  useEffect(() => {
    const handleReceivedMessage = (history) => {
      setChatThreadHistory((prev) => {
        const newData =
          prev?.data && Array.isArray(prev?.data)
            ? [...prev.data, history?.data]
            : [history?.data];
        return {
          ...prev,
          chat_room_slug: history.data?.chat_room_slug,
          data: newData,
        };
      });
    };

    socketIo.on("receivedMessage_", handleReceivedMessage);

    return () => {
      socketIo.dispose("receivedMessage_", handleReceivedMessage);
    };
  }, [socketIo]);

  // MARK: New Thread
  useEffect(() => {
    const handleNewThread = (newThread) => {
      console.log("New thread received:", newThread.data?.chat_room_slug);
      setSubmitLoading(false);
      setChatThreadHistory((prev) => ({
        ...prev,
        chat_room_slug: newThread.data?.chat_room_slug,
        data: [newThread?.data],
      }));
      getChatThreads();
      joinRoom(newThread.data?.chat_room_slug);
    };

    socketIo.on("newThread_", handleNewThread);

    return () => {
      socketIo.dispose("newThread_", handleNewThread);
    };
  }, [socketIo]);

  useEffect(() => {
    // Function to update the screen width
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Attach the resize event listener
    window.addEventListener("resize", handleResize);

    // Clean up the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  console.log(pagination);

  useEffect(() => {
    if (chatThreadHistory?.data && page === 1) {
      // setChatThreadHistory(data.data);
      setHasMore(chatThreadHistory?.data.length < pagination?.total_records);
    }
  }, [chatThreadHistory?.data, page]);

  const getRecords = () => {
    console.log("get records");
    if (!hasMore) return;
    setPage((prevPage) => prevPage + 1);
  };

  useEffect(() => {
    if (page < pagination?.total_records) {
      refetch();
    }
  }, [page]);

  useEffect(() => {
    if (page > 1 && chatThreadHistory?.data) {
      // setPosts((prevPosts) => [...prevPosts, ...data.data]);
      setHasMore(
        chatThreadHistory?.data.length + chatThreadHistory?.data?.length <
          pagination?.total_records
      );
    }
  }, [chatThreadHistory?.data]);

  const searchUser = (e) => {
    try {
      const value = e.target.value;
      setSearch(value);
      if (value == "") {
        setChatThreads(threads);
        return;
      } else {
        let list = getUser?.data?.filter((v) =>
          v?.name?.toLowerCase()?.includes(value?.toLowerCase())
        );
        console.log(list, "users");
        setChatThreads([...list, ...chatThreads]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleEmojiClick = (emojiObject) => {
    setMessage((prevValue) => prevValue + emojiObject.emoji);
    // setShowEmojiPicker(!showEmojiPicker);
  };

  const deleteChatMessage = (messlug, is_fromEveryone = false) => {
    setDeleteLoading(true);
    const message_slug = messlug ? messlug : messageSlug;
    socketIo.emit("_deleteChatMessage", { message_slug, is_fromEveryone });

    socketIo.on("deleteChatMessage_", (data) => {
      if (data?.code === 200) {
        setDeleteLoading(false);
        SuccessToast("Message Deleted Successfully");
        setDeleteModal(false);
        // setDeleteConfirm(false);
        setChatThreadHistory((prev) => {
          return {
            ...prev,
            data: prev.data.filter(
              (message) => message.message_slug !== message_slug
            ),
          };
        });
      }
    });
  };

  const deleteChatThread = () => {
    setDeleteLoading(true);
    const chat_room_slug =
      chatThreadHistory?.chat_room_slug || chatThreadHistory?.slug;
    socketIo.emit("_deleteChatThread", { chat_room_slug });

    socketIo.on("deleteChatThread_", (data) => {
      if (data?.code === 200) {
        SuccessToast("Chat thread deleted");
        setDeleteThreadConfirm(false);
        setDeleteThreadLoading(false);
        if (chatThreadHistory?.chat_room_slug === chat_room_slug) {
          setChatThreadHistory((prev) => {
            return {
              ...prev,
              data: [],
            };
          });
        }
      }
    });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatThreadHistory?.data]);

  return (
    <div className={`${styles.chat}`}>
      <Row className="h-100vh">
        <Col
          md={12}
          lg={8}
          className={`${styles.colChat} d-lg-block ${
            screenWidth <= 991 && !show ? "" : "d-none"
          }`}
        >
          <div className={styles.userChat}>
            <div
              className={`d-flex ${styles.backIcon} cursor align-items-center`}
            >
              {/* <div onClick={() => route.back()}>
                <MdArrowBackIosNew /> <span className="ms-3">Chat</span>
              </div> */}
              {!show && (
                <div
                  className={`d-md-none d-block cursor ms-auto ${styles.chats}`}
                  onClick={() => setShow(!show)}
                >
                  <TbMessageCircleFilled /> Chats
                </div>
              )}
            </div>
            {chatThreadHistory?.slug || chatThreadHistory?.chat_room_slug ? (
              <div className={`${styles.chatContainer} mb-5 mt-3`}>
                <div
                  className={`${styles.userInfoSec} d-flex align-items-center`}
                >
                  <ImageWithPlaceholder
                    src={
                      (chatThreadHistory?.user_images?.length > 0 &&
                        chatThreadHistory.user_images[0]?.image_url) ||
                      chatThreadHistory?.image_url
                    }
                    width={40}
                    height={40}
                    alt="user-image"
                    className="object-fit-contain image-fluid rounded-circle"
                    placeholder={"/images/user-placeholder.jpeg"}
                  />
                  <p className="p-0 m-0 ms-3">
                    {chatThreadHistory?.firstname
                      ? `${chatThreadHistory?.firstname} ${chatThreadHistory?.lastname}`
                      : chatThreadHistory?.username
                      ? chatThreadHistory?.username
                      : chatThreadHistory?.name
                      ? chatThreadHistory?.name
                      : chatThreadHistory?.room_name || "N/A"}
                  </p>
                </div>
                <div className={styles.chatsList}>
                  <div className={styles.list} ref={chatContainerRef}>
                    {chatThreadHistory.data &&
                      chatThreadHistory.data.length > 0 && (
                        <>
                          {chatThreadHistory?.data?.map((dt, index) =>
                            dt?.user_slug !== login_User ? (
                              <div
                                key={index}
                                className={`w-100 ${styles.userRecieveSec} d-flex mb-2 flex-column align-items-start mt-2`}
                                onClick={() => {setDeleteModal(true); setMessageSlug(dt.message_slug)}}
                              >
                                <div className={`${styles.userName} mb-1`}>
                                  {dt?.name}
                                </div>
                                {dt.file_url && (
                                  <div
                                    className="position-relative max-w-40 max-h-40 rounded-3xl"
                                    style={{
                                      maxWidth: "150px",
                                      width: "auto",
                                    }}
                                  >
                                    <ImageWithPlaceholder
                                      src={dt?.file_url}
                                      alt="Image"
                                      className="img-fluid rounded"
                                      style={{
                                        maxWidth: "100%",
                                        height: "auto",
                                      }}
                                      placeholder={
                                        "/images/user-placeholder.jpeg"
                                      }
                                    />
                                  </div>
                                )}
                                <div
                                  className={`${styles.message} d-flex align-items-end mt-2`}
                                >
                                  {dt?.message && (
                                    <p className="m-0">{dt?.message}</p>
                                  )}
                                  <span className="ms-2">
                                    {formatTime(dt?.message_timestamp)}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div
                                key={index}
                                className={`w-100 ${styles.userSenderSec} d-flex mb-2 flex-column align-items-end`}
                                onClick={() => {setDeleteModal(true); setMessageSlug(dt.message_slug)}}
                              >
                                <div className={`${styles.userName} mb-1`}>
                                  You
                                </div>
                                {dt.file_url && (
                                  <div
                                    className="position-relative rounded-3"
                                    style={{
                                      maxWidth: "150px",
                                      width: "auto",
                                    }}
                                  >
                                    <img
                                      src={dt?.file_url}
                                      alt="Image"
                                      className="img-fluid rounded"
                                      style={{
                                        maxWidth: "100%",
                                        height: "auto",
                                      }}
                                    />
                                  </div>
                                )}
                                <div
                                  className={`${styles.message} mt-2 d-flex align-items-end`}
                                >
                                  <span className="">
                                    {formatTime(dt?.message_timestamp)}
                                  </span>
                                  {dt?.message && (
                                  <p className="m-0 ms-2 text-white">{dt?.message}</p>
                                  )}
                                </div>
                              </div>
                            )
                          )}
                        </>
                      )}
                  </div>
                  <div className="position-relative">
                    {uploadedImages.length > 0 && (
                      <div
                        className="position-absolute"
                        style={{
                          bottom: "50px",
                          overflowX: "auto",
                          maxWidth: "95%",
                          WebkitOverflowScrolling: "touch",
                          width: "95%",
                        }}
                      >
                        <div className="d-flex mx-1 me-3 p-3 justify-content-start align-items-center bg-white rounded-3">
                          {uploadedImages.map((file, index) => (
                            <div
                              key={index}
                              className="position-relative me-2"
                              style={{ width: "64px", height: "64px" }}
                            >
                              {file?.type.startsWith("image/") ? (
                                <img
                                  className="w-100 h-100 object-fit-cover rounded-2"
                                  src={URL.createObjectURL(file)}
                                  alt={`uploaded-file-${index}`}
                                />
                              ) : file?.type.startsWith("video/") ? (
                                <video
                                  className="w-100 h-100 object-fit-cover rounded-2"
                                  controls
                                >
                                  <source src={URL.createObjectURL(file)} />
                                </video>
                              ) : null}

                              <button
                                className="position-absolute top-0 end-0 btn btn-danger btn-sm p-1 rounded-circle"
                                onClick={() => handleRemoveImage(index)}
                                style={{
                                  width: "20px",
                                  height: "20px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  // padding: "2px",
                                }}
                              >
                                x
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}

                    <div className={styles.formInput}>
                      <Form
                        onSubmit={handleSubmit(onSubmit)}
                        className="d-flex"
                      >
                        <div className="w-100 d-flex align-items-center">
                          <input
                            type={"text"}
                            name="message"
                            control={control}
                            errors={errors}
                            placeholder="Type Message Here..."
                            className={`theme-input ${styles.formControl}`}
                            value={message}
                            onChange={(e) => setMessage(e?.target?.value)}
                            autoComplete="off"
                            aria-autocomplete="off"
                          />
                          <div className={styles.emoji}>
                            <ImageWithPlaceholder
                              src={"/images/emoji.svg"}
                              width={25}
                              height={25}
                              alt="user image"
                              className="placeholder-img object-fit-contain image-fluid cursor"
                              priority
                              onClick={() => {
                                setShowEmojiPicker(!showEmojiPicker);
                              }}
                            />
                          </div>
                          <div className={`${styles.attachment} d-flex`}>
                            <label
                              htmlFor="file-upload"
                              className="cursor"
                              onClick={() =>
                                document.getElementById("imageUpload").click()
                              }
                            >
                              <GrAttachment size={15} />
                            </label>
                            <input
                              type="file"
                              accept="image/*,video/*"
                              id="imageUpload"
                              onChange={handleImageUpload}
                              className="d-none"
                            />
                          </div>
                          {showEmojiPicker && (
                            <EmojiPicker
                              className="emoji-picker-component emoji-picker"
                              onEmojiClick={(e) => handleEmojiClick(e)}
                              open={showEmojiPicker}
                              style={{
                                height: "280px",
                                width: "350px",
                                position: "absolute",
                                bottom: "50px",
                                overflow: "auto",
                                left: "5px",
                              }}
                            />
                          )}
                        </div>
                        <Button
                          className="theme-btn ms-2"
                          onClick={handleSubmit(onSubmit)}
                        >
                          {submitLoading ? (
                            <Spinner animation="border" size="sm" />
                          ) : (
                            <BsSend size={18} />
                          )}
                        </Button>
                      </Form>
                      {selectedFile && (
                        <div className="w-100 selected-file">
                          {selectedFile}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="chat-container mb-5 mt-3 text-grey w-100 h-100 d-flex justify-content-center align-items-center text-center">
                <div>
                  <AiFillMessage className="fs-1 text-center" />
                  <h6 className="cfont-italic fs-5 mt-3">
                    Select a chat to start messaging
                  </h6>
                </div>
              </div>
            )}
          </div>
        </Col>
        <Chatlist
          searchUser={searchUser}
          chatThreads={chatThreads}
          setChatThreadHistory={setChatThreadHistory}
          searchUsers={searchUsers}
          getUserLoading={isLoadingGetUsers}
          chatThreadHistory={chatThreadHistory}
          getChatHistory={getChatHistory}
          getFindOrCreateRoom={getFindOrCreateRoom}
          loading={loading}
          search={search}
          showUnread={showUnread}
          pagination={pagination}
          setPagination={setPagination}
          screenWidth={screenWidth}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          userData={userData}
          setUserData={setUserData}
          show={show}
          setShow={setShow}
          setScreenWidth={setScreenWidth}
          getLoadChatHIstory={getLoadChatHIstory}
        />
      </Row>
      {deleteModal && (
        <DeleteChatModal
          setDeleteModal={setDeleteModal}
          text="Are you sure you want to delete Message from Everyone?"
          handleSubmit={() => {
            deleteChatMessage("", true);
          }}
          isLoading={deleteLoading}
          handleCancel={() => {
            deleteChatMessage("", false);
          }}
          handleDeleteModalClose={() => setDeleteModal(false)}
        />
      )}
      {deleteThreadConfirm && (
        <DeleteModal
          setDeleteModal={setDeleteThreadConfirm}
          text="Are you sure you want to this delete?"
          handleSubmit={deleteChatThread}
          isLoading={deleteThreadLoading}
          handleDeleteModalClose={() => setDeleteThreadConfirm(false)}
        />
      )}
    </div>
  );
};

export default Chat;

const Chatlist = ({
  chatThreads,
  searchUser,
  loading,
  setChatThreadHistory,
  searchUsers,
  getUserLoading,
  chatThreadHistory,
  getChatHistory,
  getFindOrCreateRoom,
  search,
  showUnread,
  screenWidth,
  show,
  userData,
  chat,
  userlist,
  setShow,
  getLoadChatHIstory,
}) => {
  const {
    formState: { errors },
  } = useForm({ mode: "onChange" });

  return (
    <Col
      md={12}
      lg={4}
      className={`${styles.colUserList} d-lg-block ${
        screenWidth <= 991 && show ? "" : "d-none"
      }`}
    >
      <div className={styles.chatListContainer}>
        <div className={styles.topContent}>
          <h3>Officer List</h3>
          <div className={`${styles.searchInput} d-lg-block d-none`}>
            <input
              className={`form-input ${styles.formControl}`}
              placeholder="Search Chat"
              onChange={searchUser}
              value={search}
            />
            <BsSearch color="black" className="search-icon" />
          </div>
        </div>
        <div className={`${styles.list} mt-3`}>
          {loading ? (
            <div className="flex items-center justify-center h-40">
              {/* <ThemeSpinner /> */}
            </div>
          ) : !!chatThreads?.length ? (
            chatThreads.map((dt, index) => (
              <div
                key={dt?.id}
                className={`${styles.users} mb-3 cursor d-flex justify-content-between w-100`}
                onClick={() => {
                  if (dt?.chat_room_slug) {
                    getLoadChatHIstory(dt?.chat_room_slug);
                  } else {
                    setChatThreadHistory({ data: [] });
                  }
                  // setUserData(dt);
                  setShow(!show);
                  setChatThreadHistory((prev) => ({
                    ...prev,
                    chat_room_slug: dt?.chat_room_slug,
                    slug: dt?.user_slug || dt?.slug,
                    room_name: dt?.room_name,
                    firstname: dt?.firstname,
                    lastname: dt?.lastname,
                    name: dt?.name,
                    username: dt?.username,
                    image_url:
                      dt?.user_images?.length > 0
                        ? dt.user_images[0]?.image_url
                        : dt?.image_url,
                  }));
                }}
              >
                <div className={`${styles.userInfo} d-flex align-items-center`}>
                  <ImageWithPlaceholder
                    src={
                      dt?.user_images?.length > 0
                        ? dt.user_images[0]?.image_url
                        : dt?.image_url
                    }
                    width={47}
                    height={47}
                    alt="user-image"
                    className="object-fit-contain image-fluid rounded-circle"
                    placeholder={"/images/user-placeholder.jpeg"}
                  />
                  <div className={`${styles.name} ms-3`}>
                    <p>
                      {dt?.firstname
                        ? `${dt?.firstname} ${dt?.lastname}`
                        : dt?.username
                        ? dt?.username
                        : dt?.name
                        ? dt?.name
                        : dt?.room_name || "N/A"}
                    </p>
                    <div className={styles.message}>{dt?.message?.message}</div>
                  </div>
                </div>
                <div className={`${styles.time} d-flex flex-column`}>
                  {dt?.message?.createdAt && (
                    <p>{formatTime(dt?.message?.createdAt)}</p>
                  )}
                  {dt?.unread_message_count !== 0 &&
                    dt?.unread_message_count !== undefined && (
                      <div className={`${styles.numbers} rounded-circle`}>
                        {dt.unread_message_count}
                      </div>
                    )}
                </div>
              </div>
            ))
          ) : (
            <div className="d-flex align-items-center justify-content-center">
              <p className="fs-7 fw-bold">User not found !</p>
            </div>
          )}
        </div>
      </div>
    </Col>
  );
};
