import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.js';
import reportWebVitals from './reportWebVitals.js';
import { ReduxProvider } from './redux/reduxProvider.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { store } from './store.js';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { SocketProvider } from './context/SocketContext.js';
import { VoiceCallContextProvider } from './context/VoiceCallContext.js';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <SocketProvider>
        <VoiceCallContextProvider>
          <ToastContainer />
          <App />
        </VoiceCallContextProvider>
      </SocketProvider>
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
