import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import persistConfig from "./persistConfig";
import { themeReducer } from "./slices/toggletheme";
import { chatReducer } from "./slices/Chat";
import { postsReducer } from "./slices/post";
import { commentsReducer } from "./slices/commments";
import { CreatePostReducer } from "./slices/createPost";

const rootReducer = combineReducers({
  theme: themeReducer,
  chat: chatReducer,
  posts: postsReducer,
  comments: commentsReducer,
  createpost: CreatePostReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
