import { FaCircleXmark } from "react-icons/fa6";
import cssStyles from "./styles/editModal.module.scss";
import ModalContainer from "../ModalContainer";
import { Spinner } from "react-bootstrap";

const DeleteChatModal = ({
  setDeleteModal,
  text = "Are you sure you want to delete your Account?",
  handleSubmit = () => {},
  isLoading,
  handleCancel = () => {},
  handleDeleteModalClose,
}) => {
  return (
    <ModalContainer handleClose={handleDeleteModalClose}>
      <div className={`${cssStyles.modalContainer} p-4`}>
        <div className="d-flex align-items-center w-100 justify-content-between mb-4">
          <p className="fs-4 fw-medium m-0">Confirm Delete</p>
          <span
            className="bg-dark bg-opacity-75 d-flex p-3 rounded-5"
            onClick={handleDeleteModalClose}
          >
            <FaCircleXmark role="button" style={{ cursor: "pointer" }} />
          </span>
        </div>
        <div className="text-center mb-4">
          <p className="fs-5 text-muted">{text}</p>
        </div>
        {isLoading ? (
          <Spinner animation="border" size="sm" />
        ) : (
          <div className="d-flex justify-content-center align-items-center">
            {/* Cancel Button */}
            <div
              className="d-flex justify-content-center align-items-center rounded-3 p-3 mx-2 theme-btn cursor"
              onClick={handleCancel}
              style={{
                backgroundColor: "#f0f0f0",
                color: "#333",
                border: "1px solid #ccc",
              }}
            >
              <span className="mx-2">Yes</span>
            </div>

            {/* Delete Button */}
            <div
              className="d-flex justify-content-center align-items-center rounded-3 p-3 mx-2 bg-danger text-white cursor"
              onClick={handleSubmit}
              style={{
                backgroundColor: "#ff4d4f",
                color: "#fff",
                border: "none",
                transition: "background-color 0.3s ease",
              }}
              onMouseEnter={(e) => (e.target.style.backgroundColor = "#ff0000")}
              onMouseLeave={(e) => (e.target.style.backgroundColor = "#ff4d4f")}
            >
              <span className="mx-2">No</span>
            </div>
          </div>
        )}
      </div>
    </ModalContainer>
  );
};

export default DeleteChatModal;
