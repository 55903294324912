import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSocket } from './SocketContext';
import socket from "../config/Socket"
import _ from 'lodash';

export const VoiceCallContext = createContext();

export function useVoiceCall() {
    return useContext(VoiceCallContext);
}

// Create a provider component
export const VoiceCallContextProvider = ({ children }) => {
    const [state, setState] = useState({
        onCall: false,
        activeChannel: "",
        incoming_calls: []
    });
    const { isConnected } = useSocket()


    useEffect(() => {
        if (isConnected) {
            socket.on('_callAlert', (data) => {
                setState(prev => {
                    return {
                        ...prev,
                        incoming_calls: [...prev.incoming_calls, data]
                    }
                })
            })


            socket.on('callHangUp_', (data) => {
                removeIncommingCall(data?.channel_name);
            })



            socket.on("getActiveCalls_", (data) => {
                if (_.isEmpty(data)) return;

                const calls = data?.map(item => {
                    return {
                        channel_name: item?.channel_name,
                        to_user_id: item?.to_user.id,
                        to_user_slug: item?.to_user.slug,
                        to_user_name: item?.to_user.name,
                        to_user_image: item?.to_user.image_url,
                        from_user_id: item?.from_user.id,
                        from_user_slug: item?.from_user.slug,
                        from_user_name: item?.from_user.name,
                        from_user_image: item?.from_user.image_url,
                        is_video: item?.is_video
                    }
                })
                setState(prev => {
                    return {
                        ...prev,
                        incoming_calls: [...prev.incoming_calls, ...calls]
                    }
                })
            })


            socket.emit('_getActiveCalls')
        }

        return () => {
            socket?.dispose('_callAlert')
            socket?.dispose('callHangUp_')
        }

    }, [isConnected])


    const handleReject = (channel_name, user_slug) => {
        socket.emit("_rejectCall", {
            channel_name: channel_name,
            other_user_slug: user_slug
        })
        removeIncommingCall(channel_name, { onCall: false, activeChannel: "" })
    }



    const handleOnCallReject = (channel_name, user_slug) => {
        socket.emit("_rejectCall", {
            channel_name: channel_name,
            other_user_slug: user_slug
        })
        removeIncommingCall(channel_name)
    }


    const handleAccept = (channel_name, user_slug) => {
        socket.emit("_callAccepted", {
            channel_name: channel_name,
            other_user_slug: user_slug
        })
    }

    const removeIncommingCall = (channel_name, params = {}) => {
        setState(prev => {
            return {
                ...prev,
                ...params,
                incoming_calls: prev.incoming_calls?.filter(item => item.channel_name !== channel_name)
            }
        })
    }

    const handleEndCall = () => {
        setState(prev => {
            return {
                ...prev,
                onCall: false,
            }
        })
    }

    const updateActiveChannel = (channel_name) => {
        setState(prev => {
            return {
                ...prev,
                activeChannel: channel_name
            }
        })
    }

    return (
        <VoiceCallContext.Provider value={{
            incoming_calls: state?.incoming_calls,
            activeChannel: state?.activeChannel,
            handleCallReject: handleReject,
            handleOnCallReject: handleOnCallReject,
            handleCallAccept: handleAccept,
            handleEndCall: handleEndCall,
            removeIncommingCall: removeIncommingCall,
            updateActiveChannel: updateActiveChannel
        }}>
            {children}
        </VoiceCallContext.Provider>
    );
};
