import { createSlice } from "@reduxjs/toolkit";
import { offcerApi } from "../services/officerApi";

const initialState = {
  data: null,
  officerSlug: null,
};

export const officerSlice = createSlice({
  name: "offcer",
  initialState,
  reducers: {
    setOffers: (state, action) => {
      state.data = action.payload;
    },
    setOffersSlug: (state, action) => {
      state.officerSlug = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        offcerApi.endpoints.getAllOfficers.matchFulfilled,
        (state, { payload }) => {
          state.data = payload.data;
        }
      )
      .addMatcher(offcerApi.endpoints.getAllOfficers.matchRejected, (state) => {
        state.data = null;
      });
  },
});

export const {setOffers, setOffersSlug} = officerSlice.actions;

export default officerSlice.reducer;
