import { createSlice } from "@reduxjs/toolkit";

const initialState = false; 

const heatMapSlice = createSlice({
  name: "heatMap",
  initialState, 
  reducers: {
    toggleHeatMap: (state) => {
      return !state; 
    },
  },
});

export const { toggleHeatMap } = heatMapSlice.actions;

const heatMapReducer = (state = initialState, action) => {
  switch (action.type) {
    case toggleHeatMap.type:
      return !state; 
    default:
      return state; 
  }
};

export { heatMapReducer };
