import moment from "moment";
const baseRoute = (name = '') => {
    return `${process.env.REACT_APP_DOMAIN_DIR || ''}/${name}`
}

const apiBaseUrl = () => {
    return process.env.REACT_APP_API_DOMAIN + 'api/user/'
}

const localStorageToken = () => {
    return process.env.REACT_APP_WEB_STORAGE_TOKEN;
}
const formatTime = (time) => {
    return moment(time).format("LT");
  };
const formatDateTime = (date) => {
    return moment().format('lll')
  };





export { baseRoute, apiBaseUrl, localStorageToken, formatTime, formatDateTime }







