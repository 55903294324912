import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styles from "../Layouts/styles/Navbar.module.scss";
import notificationIcon from "../../assets/images/notiside.svg";
import { useGetNotificationListQuery } from "../../Data/services/notificationApi";

const Notification = ({ notiModal, setNotiModal }) => {
  const { data: record, error, isLoading } = useGetNotificationListQuery();
  const [notificationsAll, setNotificationsAll] = useState([]);
  const data = record?.data || [];

  useEffect(() => {
    if (data) {
      const formatNotifications = (data) => {
        const notifications = [];

        const formatTime = (dateString) => {
          const date = new Date(dateString);
          return `${date.getHours()}:${
            date.getMinutes() < 10 ? "0" : ""
          }${date.getMinutes()}`;
        };

        const getDayLabel = (createdAt) => {
          const today = new Date();
          const createdDate = new Date(createdAt);
          if (
            createdDate.getDate() === today.getDate() &&
            createdDate.getMonth() === today.getMonth() &&
            createdDate.getFullYear() === today.getFullYear()
          ) {
            return "Today";
          } else if (
            createdDate.getDate() === today.getDate() - 1 &&
            createdDate.getMonth() === today.getMonth() &&
            createdDate.getFullYear() === today.getFullYear()
          ) {
            return "Yesterday";
          } else {
            return createdDate.toLocaleDateString();
          }
        };

        const groupedNotifications = data?.reduce((acc, item) => {
          const dayLabel = getDayLabel(item.created_at);
          const formattedNotification = {
            id: item.slug,
            name: item.title.split(" ")[0],
            type: item.type,
            time: formatTime(item.created_at),
            unread: true,
            date: dayLabel,
          };

          if (!acc[dayLabel]) {
            acc[dayLabel] = [];
          }
          acc[dayLabel].push(formattedNotification);
          return acc;
        }, {});

        const result = Object.keys(groupedNotifications).map((day) => ({
          day: day.toUpperCase(),
          data: groupedNotifications[day],
        }));

        return result;
      };

      if (data?.length > 0) {
        const formattedNotifications = formatNotifications(data);
        setNotificationsAll(formattedNotifications);
      }
    }
  }, [data]);

  const [activeTab, setActiveTab] = useState("all"); // 'all' or 'unread'
  // const notificationsAll = [
  //   {
  //     id: "today",
  //     day: "TODAY",
  //     data: [
  //       {
  //         id: 1,
  //         name: "Bessie Cooper",
  //         type: "Message",
  //         time: "08:15",
  //         unread: true,
  //         date: "Today",
  //       },
  //       {
  //         id: 2,
  //         name: "Update",
  //         type: "Update your Platform",
  //         time: "08:15",
  //         unread: true,
  //         date: "Today",
  //       },
  //       {
  //         id: 3,
  //         name: "Courtney Henry",
  //         type: "Post an Ad",
  //         time: "08:15",
  //         unread: false,
  //         date: "Today",
  //       },
  //     ],
  //   },
  //   {
  //     day: "YESTERDAY",
  //     data: [
  //       {
  //         id: 4,
  //         name: "Esther Howard",
  //         type: "Saved Your Ad",
  //         time: "08:15",
  //         unread: false,
  //         date: "Yesterday",
  //       },
  //       {
  //         id: 5,
  //         name: "Kathryn Murphy",
  //         type: "Saved Your Ad",
  //         time: "08:15",
  //         unread: false,
  //         date: "Yesterday",
  //       },
  //     ],
  //   },
  // ];
  console.log(notificationsAll);
  return (
    <div className={`${!notiModal && "d-none"} ${styles.notificationPanel}`}>
      <div className={`${styles.notificationHeader}`}>
        <p>Notification</p>
        <button
          className={styles.closeBtn}
          onClick={() => setNotiModal(!notiModal)}
        >
          ✕
        </button>
      </div>
      <div className={`${styles.notificationHeaderContent} mt-4`}>
        <div className={styles.tabs}>
          <button
            className={`${styles.tab} ${
              activeTab === "all" ? `${styles.active}` : ""
            }`}
            onClick={() => setActiveTab("all")}
          >
            All Notification
          </button>
          <button
            className={`${styles.tab} ${
              activeTab === "unread" ? `${styles.active}` : ""
            }`}
            onClick={() => setActiveTab("unread")}
          >
            Unread
          </button>
        </div>
      </div>
      <div className={styles.notificationContent}>
        {notificationsAll?.length > 0 ? 
        notificationsAll?.map((dt) => (
          <div className={styles.content} key={dt?.id}>
            <div className={`${styles.day} mb-2`}>{dt?.day}</div>
            {dt?.data?.map((notidt) => (
              <div
                className={`${styles.notificationItem} mb-3 d-flex`}
                key={notiModal?.id}
              >
                <div className={styles.notificationAvatar}>
                  <img
                    src={notificationIcon}
                    alt="Avatar"
                    className="object-fit-contain image-fluid"
                  />
                </div>
                <div className={styles.details}>
                  <h3>{notidt?.name}</h3>
                  <p>{notidt?.type}</p>
                </div>
                <div className={`ms-auto ${styles.timeSec} d-flex`}>
                  <div
                    className={`${styles.time} ${
                      notidt?.unread && `${styles.unread}`
                    }`}
                  >
                    {notidt?.time}
                  </div>
                  {notidt?.unread && (
                    <div className={`${styles.unreadDot} ms-auto`}></div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )) : <p className="fs-7 w-100 d-flex justify-content-center h-auto">Notification Not Available</p>}
      </div>
    </div>
  );
};

export default Notification;
