import React, { useMemo } from "react";
import {
  Navigate,
  Route,
  BrowserRouter as Router,
  Routes
} from 'react-router-dom';

// global scss for globally styles and fonts
import './_fonts.scss';
import './_global.scss';

import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
import { uniqueId } from "lodash";
import ProtectedLayout from "./Components/Layouts/ProtectedLayout.js";
import ProtectedRoute from "./Components/Routes/ProtectedRoute.js";
import PublicRoute from "./Components/Routes/PublicRoute.js";
import { WEB_ROUTES } from "./constants.js";
import { baseRoute } from "./Helper";
import Account from "./Pages/Account";
import Alert from "./Pages/Alert/index.jsx";
import BookingHistory from "./Pages/Booking-History/index.js";
import Chat from "./Pages/Chat/index.jsx";
import Dashboard from "./Pages/Dashboard";
import Dispatcher from "./Pages/Dispatcher/index.js";
import Emergency from "./Pages/Emergency/index.jsx";
import HeatMap from "./Pages/HeatMap/index.js";
import JoinCall from "./Pages/JoinCall/index.js";
import Normal from "./Pages/Normal/index.jsx";
import Setting from "./Pages/Setting";
import VoiceCall from "./Pages/VoiceCall/index.js";

const client = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

function App() {
  // todo: menu


  const ROUTES = useMemo(() => {
    return [
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.ACCOUNT)} element={
        <PublicRoute >
          <Account />
        </PublicRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.BOOKINGHISTORY)} element={
        <ProtectedRoute>
        <ProtectedLayout isSearchBar={false} selectedRoute={''} onBooking={true}>
          <BookingHistory />
        </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={`${baseRoute(WEB_ROUTES.BOOKINGHISTORY)}/:id`} element={
        <ProtectedRoute>
        <ProtectedLayout isSearchBar={false} selectedRoute={''} onBooking={true}>
          <Dispatcher />
        </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={`${baseRoute(WEB_ROUTES.HEATMAP)}/:id`} element={
        <ProtectedRoute>
        <ProtectedLayout isSearchBar={false} selectedRoute={''} onBooking={true}>
          <HeatMap />
        </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.DASHBOARD)} element={
        <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={0}>
            <Dashboard />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.NORMAL)} element={
        <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={1}>
            <Normal />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.ALERT)} element={
        <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={2}>
            <Alert />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.EMERGENCY)} element={
        <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={3}>
            <Emergency />
          </ProtectedLayout>
        // </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.SETTING)} element={
        <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={4}>
            <Setting />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
      // <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.STREAMING)} element={
      //   // <ProtectedRoute>

      //   <ProtectedLayout isSearchBar={false} >
      //     <AgoraRTCProvider client={client}>
      //       <Streaming />
      //     </AgoraRTCProvider>
      //   </ProtectedLayout>
      //   // </ProtectedRoute>
      // } />,

      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.VOICE_CALL)} element={
        <ProtectedRoute>
          <AgoraRTCProvider client={client}>
            <VoiceCall />
          </AgoraRTCProvider>
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.JOIN_CALL)} element={
        <ProtectedRoute>
          <JoinCall />
        </ProtectedRoute>
      } />,
      <Route key={uniqueId} exact path={baseRoute(WEB_ROUTES.CHAT)} element={
        <ProtectedRoute>
          <ProtectedLayout isSearchBar={false} selectedRoute={4}>
            <Chat />
          </ProtectedLayout>
        </ProtectedRoute>
      } />,
    ]

  }, [])

  return (
    <Router>
      <Routes>
        {ROUTES.map((item) => item)}
        {/* <Route path="/" element={<Navigate to={WEB_ROUTES.ACCOUNT} />} /> */}
        <Route path="*" element={<Navigate to={WEB_ROUTES.ACCOUNT} />} />
      </Routes >


    </Router >
  );
}

export default App;
