
import { io } from "socket.io-client";
import { BASE_URL } from "./config";

const SOCKET_URL = BASE_URL  
class SocketIo {
  constructor() {
    this.socket = null;
  }

  connectToSocket = (api_token, callback = () => {}, success = () => {}) => {    
    this.socket = io(SOCKET_URL, {
        transports: ["websocket"],
        query: { authorization: api_token },
        autoConnect: false, 
      });

    this.socket.connect();

    this.socket.on("connect", () => {
      console.log(`Socket Connected: ${this.socket.id}`);
      success(this.socket);
    });

    this.socket.on("connect_error", (err) => {
      console.log("Socket connection error:", err);
      callback(err);
    });
  };

  emit = (eventName, params = {}, onSuccess) => {
    if (this.socket) {
      this.socket.emit(eventName, params, (res) => {
        if (onSuccess) {
          onSuccess(res.data);
        }
      });
    } else {
      console.error("Socket not connected. Cannot emit event.");
    }
  };

  on = (eventName, callback) => {
    if (this.socket) {
      this.socket.on(eventName, callback);
    } else {
      console.error("Socket not connected. Cannot listen for events.");
    }
  };

  dispose = (eventName) => {
    if (this.socket) {
      this.socket.off(eventName);
    }
  };

  disconnect = () => {
    if (this.socket) {
      this.socket.disconnect();
      console.log("Socket disconnected.");
    }
  };

  getStatus = () => {
    return this.socket ? this.socket.connected : false;
  };
}

export default new SocketIo();