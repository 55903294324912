import { createSlice } from '@reduxjs/toolkit'
import { userAuthApi } from '../services/userAuthApi';

const initialState = {
  user: null,
  isLoggedIn: false,
  isRememberMe: false,
  isSocketConnected: false,
}

export const authSlice = createSlice({
  name: 'authInfo',
  initialState,
  reducers: {
    setUser: (state, actions) => {
      state.isLoggedIn = true
      state.user = actions.payload
    },
    setRememberMe: (state, actions) => {
      state.isRememberMe = actions.payload || false
    },
    logout: (state) => {
      state.isLoggedIn = false;
      state.isSocketConnected = false
      state.user = {};
    },
    socketConntected: (state, actions) => {
      return {
        ...state,
        isSocketConnected: true
      }
    },

  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        userAuthApi.endpoints.loginUser.matchFulfilled,
        (state, { payload }) => {
          state.isLoggedIn = true
          state.user = payload.data;
        }
      )
      .addMatcher(userAuthApi.endpoints.loginUser.matchRejected, (state) => {
        state.user = null;
      });
  }
})

export const { setUser, setRememberMe, logout, socketConntected } = authSlice.actions

export default authSlice.reducer