import React from "react";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Button,
  CustomInput,
  Description,
  SubTitle,
} from "../../Components/CustomComponents";
import AccountLayout from "../../Components/Layouts/AccountLayout";
import { WEB_ROUTES } from "../../constants";
import { useLoginUserMutation } from "../../Data/services/userAuthApi";
import { ErrorToast, SuccessToast } from "../../Hooks/useToast";
import { ACCOUNT_ENUM } from "./Route";
import { localStorageToken } from "../../Helper";
import { storeToken } from "../../Data/services/localStorageService";

export default function Login({ setPage }) {
  const [loginUser, { isLoading }] = useLoginUserMutation();
  const { handleSubmit, control, register } = useForm();
  const navigate = useNavigate();
  // const pagename = useSelector((state) => state.pagename.pagename);

  const handleForgotPasswordClick = () => {
    setPage(ACCOUNT_ENUM.FORGOT_PASSWORD);
  };

  const handleLogin = async (data) => {
    try {
      data.device_type = "web";
      data.device_token = "123";

      const response = await loginUser(data).unwrap();
      SuccessToast(response?.message);
      storeToken(response?.data?.api_token)
    } catch (error) {
      console.log(error);
      ErrorToast(error?.data?.message || "An error occurred during login");
    }
  };

  return (
    <AccountLayout>
      {
        // <Title text="Welcome to" classes="mb-2 fs-4" />
      }
      <SubTitle text="Leeps Dispatcher" classes="mb-2" />

      <Form onSubmit={handleSubmit(handleLogin)}>
        <Controller
          control={control}
          name="email"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3"
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                placeholder="Email"
                type="email"
                onChange={onChange}
              />
            </InputGroup>
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <InputGroup
              className="mb-3"
              style={{
                width: "300px",
              }}
            >
              <CustomInput
                type="password"
                placeholder="Password"
                onChange={onChange}
              />
            </InputGroup>
          )}
        />
        <Button
          text={!isLoading ? "Login" : <Spinner />}
          classes="mt-3 mb-2"
          type="submit"
        />
      </Form>
      <Description
        classes="cursor-pointer"
        text="Forgot Password ?"
        onClick={handleForgotPasswordClick}
      />
    </AccountLayout>
  );
}
