import { configureStore } from "@reduxjs/toolkit";
// Or from '@reduxjs/toolkit/query/react';
import { setupListeners } from '@reduxjs/toolkit/query';

import sessionMiddleware from "./Data/middleware/sessionMiddleware";

import { baseApi, baseApiAuth } from "./Data/services/baseApi";
import { userAuthApi } from "./Data/services/userAuthApi";
import officerReducer from "./Data/features/officerSlice";
import authReducer from "./Data/features/authSlice"
import { offcerApi } from "./Data/services/officerApi";
import { agoraApi } from "./Data/services/agoraApi";
import { notificationApi } from "./Data/services/notificationApi";
import { heatMapReducer } from "./Data/features/heatMapSlice";

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    // RTK Query API
    [userAuthApi.reducerPath]: userAuthApi.reducer,
    [offcerApi.reducerPath]: offcerApi.reducer,
    [agoraApi.reducerPath]: agoraApi.reducer,
    [notificationApi.reducerPath]:notificationApi.reducer,

    // Local storage Redux
    authInfo: authReducer,
    officerInfo: officerReducer,
    heatMap: heatMapReducer
  },
  // load from local storage
  preloadedState: loadFromLocalStorage(), //todo:

  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  //todo:
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      // userAuthApi.middleware,
      baseApi.middleware,
      baseApiAuth.middleware,
      userAuthApi.middleware,
      offcerApi.middleware,
      agoraApi.middleware,
      notificationApi.middleware,
      sessionMiddleware, // for session check each api response
    ]),
});

//todo:
function saveToLocalStorage(state) {
  try {
    const serialState = JSON.stringify(state)
    localStorage.setItem("reduxStore", serialState)
  } catch (e) {
    console.warn(e);
  }
}

//todo:
function loadFromLocalStorage() {
  try {
    const serialisedState = localStorage.getItem("reduxStore");
    if (serialisedState === null) return undefined;
    return JSON.parse(serialisedState);
  } catch (e) {
    console.warn(e);
    return undefined;
  }
}

//todo:
store.subscribe(() => saveToLocalStorage(store.getState()));

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch) //todo:
