import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // Defaults to localStorage for web

const persistConfig = {
  key: "root",
  storage,
  blacklist: [], // Reducer keys to omit from persisting
};

export default persistConfig;
