import React, { useEffect, useState } from "react";
import styles from "./style/Dispatcher.module.scss";
import { VitalsGraph } from "../../Components/Modals/VitalModal";
import {
  CardTitle,
  ImageWithPlaceholder,
} from "../../Components/CustomComponents";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { FaSitemap, FaXmark } from "react-icons/fa6";
import { toggleHeatMap } from "../../Data/features/heatMapSlice";
import MapFixed from "../../Components/Map/MapFixed";
import moment from "moment";
import {
  useGetOfficerLogsQuery,
  useMarkOfficerMutation,
} from "../../Data/services/officerApi";
import { SuccessToast } from "../../Hooks/useToast";
import { setOffersSlug } from "../../Data/features/officerSlice";
import { formatDateTime } from "../../Helper";
import socketIo from "../../config/Socket";

const Dispatcher = () => {
  const { id } = useParams();
  const dispachterdata = useSelector((state) => state.officerInfo?.data);
  const getDispactherData = dispachterdata?.find(
    (item) => item.id === Number(id)
  );
  console.log(getDispactherData, "Dispact");
  const heatMap = useSelector((state) => state.heatMap);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [markOfficer] = useMarkOfficerMutation();
  const [data, setData] = useState({});
  const oneHourFromNow = moment().add(1, 'hour').format();
  const { data: officerdata, error } = useGetOfficerLogsQuery(
    {
      id: data.id ? data.id : '',
      start_datetime: oneHourFromNow,
      end_datetime: moment().format(),
    },
    { refetchOnMountOrArgChange: true }
  );
  const officerlog = officerdata?.data?.map((x) => {
    return {
      timestamp: x?.datetime,
      beat_count: x?.heart_rate,
    };
  });

  function beatColor(beatCount) {
    if (beatCount >= 95 && beatCount <= 240) {
      return "#FF0000";
    } else if (beatCount >= 85 && beatCount < 95) {
      return "#FE740B";
    } else if (beatCount >= 40 && beatCount < 85) {
      return "#23AD23";
    }
    return "#000";
  }

  const handleBack = () => {
    dispatch(toggleHeatMap());
  };

  const handleResponse = (data, successMessage, errorMessage) => {
    setData(data);
  };

  const officerUpdate = async () => {
    try {
      const result = await markOfficer(getDispactherData.id);
      console.log(result);

      if (result?.data?.code === 200) {
        SuccessToast("Notification Status Updated");
      }
    } catch (e) {
      console.error("Error:", e.response ? e.response.data : e.message);
    }
  };

  const joinRoom = (userId) => {
    socketIo.emit("_joinUserRoom", userId);
    socketIo.emit("_getOfficer", getDispactherData?.slug);
    socketIo.on("getOfficer_", (data) =>
      handleResponse(data, "", "Error getting officer details")
    );
  };

  const leaveRoom = (userId) => {
    socketIo.emit("_leaveUserRoom", userId);
  };

  useEffect(() => {
    joinRoom(id);
    return () => {
      leaveRoom(id);
    };
  }, [id]);

  return (
    <>
      {heatMap && getDispactherData.heart_rate >= 85 ? (
        <main className={styles.HeatMap}>
          <p className={`${styles.xmark} d-flex p-3 rounded-5 z-`}>
            <FaXmark
              role="button"
              onClick={handleBack}
              style={{ cursor: "pointer" }}
            />
          </p>

          <div
            style={{
              width: "100%",
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MapFixed location={{ lat: getDispactherData?.latitude ? getDispactherData?.latitude : 24.9324, lng:  getDispactherData?.longitude ? getDispactherData?.longitude : 67.0873 }} />
          </div>
        </main>
      ) : (
        <div
          className={`${
            getDispactherData.heart_rate >= 85 ? "max-w-lg-75" : ""
          } ${styles.vitalsModal}`}
        >
          <div className="d-flex align-items-center justify-content-between w-100 mb-4">
            <div
              className={`${styles.vitalleft} ${getDispactherData.heart_rate <= 85 ? styles.fit : styles.full}`}
              // style={{
              //   width: getDispactherData.heart_rate <= 85 && "fit-content",
              // }}
            >
              <div className="w-100 d-flex justify-content-center">
                <div className={styles.header}>
                  <ImageWithPlaceholder
                    src={
                      data.image_url
                        ? data.image_url
                        : "/images/user-placeholder.jpeg"
                    }
                    alt="Officer"
                    className={styles.profileImage}
                    placeholder={"/images/user-placeholder.jpeg"}
                  />
                  <div className={styles.userInfo}>
                    <h5 className="text-capitalize">{data.name}</h5>
                  </div>
                </div>
              </div>
              <div className={styles.content}>
                <div className={styles.infoSection}>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Badge:</strong>
                    </p>
                    <p className="text-black">{data?.badge_no}</p>
                  </div>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Last Report:</strong>
                    </p>
                    <p className="text-black">
                      {formatDateTime(data?.createdAt)}
                    </p>
                  </div>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>HR:</strong>
                    </p>
                    <p>
                      <span
                        className={
                          beatColor(data?.heart_rate) === "#FF0000"
                            ? styles.highLighted
                            : beatColor(data?.heart_rate) === "#FE740B"
                            ? styles.waiting
                            : styles.connected
                        }
                      >
                        {data.heart_rate} bpm
                      </span>
                    </p>
                  </div>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Temperature:</strong>
                    </p>
                    <p className="text-black">
                      {getDispactherData?.officer_log?.temperature} F
                    </p>
                  </div>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Motion:</strong>
                    </p>
                    <p className="text-black">
                      {getDispactherData?.officer_log?.motion}{" "}
                    </p>
                  </div>
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Perspiration:</strong>
                    </p>
                    <p className="text-black">
                      {getDispactherData?.officer_log?.perspiration}{" "}
                    </p>
                  </div>
                  {data?.send_help === 1 && (
                    <div className={styles.infoRow}>
                      <p>
                        <strong>Status:</strong>
                      </p>
                      <p className="text-black">
                        <span className={styles.waiting}>
                          Waiting for Officer Response
                        </span>
                      </p>
                    </div>
                  )}
                  <div className={styles.infoRow}>
                    <p>
                      <strong>Connection Status:</strong>
                    </p>
                    {data.status && (
                      <p className="text-black">
                        <span className={styles.connected}>Connected</span>
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {getDispactherData.heart_rate >= 85 && (
                <div className={styles.footer}>
                  <button className={styles.officerOk} onClick={officerUpdate}>
                    <p>Officer is OK</p>
                  </button>
                  <button
                    className={styles.assistNow}
                    onClick={() => {
                      navigate("/chat");
                      dispatch(setOffersSlug(getDispactherData?.slug));
                    }}
                  >
                    <p>Assist Now</p>
                  </button>
                </div>
              )}
            </div>
            {getDispactherData.heart_rate >= 85 && (
              <div className="w-50 h-100 relative">
                <div
                  style={{
                    width: "100%",
                    height: "425px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    border: 0,
                    borderRadius: "10px",
                  }}
                >
                  <MapFixed location={{ lat: getDispactherData?.latitude ? getDispactherData?.latitude : 24.9324, lng:  getDispactherData?.longitude ? getDispactherData?.longitude : 67.0873 }} />
                </div>
                <p
                  className={`${styles.xmark} bg-opacity-75`}
                  title={heatMap ? "Heat Map" : "Toggle To View Heat Map"}
                  onClick={handleBack}
                >
                  {/* <Link
                    to={
                      heatMap &&
                      `${process.env.REACT_APP_DOMAIN_DIR}/heat-map/${getDispactherData.id}`
                    }
                  > */}
                  <FaSitemap
                    role="button"
                    style={{ cursor: heatMap && "pointer", color: "black" }}
                  />
                  {/* </Link> */}
                </p>
              </div>
            )}
          </div>
          {getDispactherData.heart_rate >= 85 && (
            <div className={styles.vitalsGraph}>
              <div className={styles.graphDiv}>
                <CardTitle text="Vitals" classes="text-black fs-5 fw-bold" />
                <p className="m-0 fs-6">Heart rate: {getDispactherData?.heart_rate}</p>
                <VitalsGraph record={officerlog} />
              </div>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default Dispatcher;
