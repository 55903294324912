import React, { useEffect, useState } from "react";
import { FaXmark } from "react-icons/fa6";
import ModalContainer from "../ModalContainer";
import cssStyles from "./styles/editModal.module.scss";
import { Description } from "../CustomComponents";
import { Form } from "react-bootstrap";
import { useSelector } from "react-redux";
import { SuccessToast } from "../../Hooks/useToast";
import {
  useGetMyProfileQuery,
  useUpdateProfileMutation,
} from "../../Data/services/notificationApi";

const SettingModal = ({ handleSettingModal }) => {
  const login_User = useSelector((state) => state.authInfo?.user?.id);
  const [updateProfile] = useUpdateProfileMutation();
  const [show_motion, setMotion] = useState(false);
  const [show_perspiration, setPrespiration] = useState(false);
  const [show_temperature, setTemperature] = useState(false);
  const { data, error, refetch } = useGetMyProfileQuery();
  
  const handleSubmit = async () => {
    try {
      const response = await updateProfile({
        show_motion: show_motion ? 1 : 0,
        show_perspiration: show_perspiration ? 1 : 0,
        show_temperature: show_temperature ? 1 : 0,
      });

      if (response.data.code === 200) {
        SuccessToast("User updated");
        refetch();
      }
    } catch (error) {
      console.error("Error updating profile", error);
    }
  };

  useEffect(() => {
    if (data?.data) {
      setTemperature(data?.data?.show_temperature === 1);
      setPrespiration(data?.data?.show_perspiration === 1);
      setMotion(data?.data?.show_motion === 1);
    }
  }, [data]);

  return (
    <div>
      <ModalContainer handleClose={handleSettingModal}>
        <div className={cssStyles.modalContainer}>
          <div className="d-flex align-items-center w-100 justify-content-between mb-4">
            <p className="fs-4 fw-medium m-0">Settings</p>
            <span
              className={`${cssStyles.xmark} bg-opacity-75 d-flex p-3 rounded-5`}
            >
              <FaXmark
                role="button"
                onClick={handleSettingModal}
                style={{ cursor: "pointer" }}
              />
            </span>
          </div>
          <div className={cssStyles.Menu}>
            <div className={`${cssStyles.option} my-3 fs-4`}>
              <Description text={"Temperature"} classes="fs-7" />
              <Form>
                <Form.Check
                  type="switch"
                  checked={show_temperature}
                  onChange={() => {
                    setTemperature(!show_temperature); 
                    handleSubmit(); 
                  }}
                />
              </Form>
            </div>
            <div className={`${cssStyles.option} my-3 fs-4`}>
              <Description text={"Motion"} classes="fs-7" />
              <Form>
                <Form.Check
                  type="switch"
                  checked={show_motion}
                  onChange={() => {
                    setMotion(!show_motion); 
                    handleSubmit(); 
                  }}
                />
              </Form>
            </div>
            <div className={`${cssStyles.option} my-3 fs-4`}>
              <Description text={"Perspiration"} classes="fs-7" />
              <Form>
                <Form.Check
                  type="switch"
                  checked={show_perspiration}
                  onChange={() => {
                    setPrespiration(!show_perspiration);
                    handleSubmit(); 
                  }}
                />
              </Form>
            </div>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default SettingModal;
