import _ from 'lodash';
import React, { useEffect } from 'react'
import { WEB_ROUTES } from '../../constants';
import { useLocation, useNavigate } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';

export default function JoinCall() {

    const navigate = useNavigate()
    const location = useLocation();
    const { state } = location;

    useEffect(() => {
        if (_.isEmpty(state)) {
            navigate(`/${WEB_ROUTES.DASHBOARD}`, {
                replace: true,
                state: null
            })
        }
        else {
            navigate(`/${WEB_ROUTES.VOICE_CALL}`, {
                replace: true,
                state: state
            })
        }
    }, [])

    return (
        <div className='w-100 h-100dvh d-flex align-items-center justify-content-center'>
            <Spinner />
        </div>
    )
}
