import React from "react";
import { FaXmark } from "react-icons/fa6";
import ModalContainer from "../ModalContainer";
import styles from "./styles/editModal.module.scss";
import profile from '../../assets/images/dispatcher_profile.png'
import { useGetOfficerLogsQuery, useMarkOfficerMutation } from "../../Data/services/officerApi";
import { formatDateTime } from "../../Helper";
import { setOffersSlug } from "../../Data/features/officerSlice";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import moment from "moment";
import { SuccessToast } from "../../Hooks/useToast";
import { ImageWithPlaceholder } from "../CustomComponents";

const AlertDispatcherModal = ({ data, handleClose }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [markOfficer] = useMarkOfficerMutation();
  const oneHourFromNow = moment().add(1, 'hour').format();
  const { data: officerdata, error } = useGetOfficerLogsQuery(
    {
      id: data.id ? data.id : '',
      start_datetime: oneHourFromNow,
      end_datetime: moment().format(),
    },
    { refetchOnMountOrArgChange: true }
  );
  const officerlog = officerdata?.data?.filter(x => x.id === data.id);

  function beatColor(beatCount) {
    if (beatCount >= 95 && beatCount <= 240) {
      return "#FF0000";
    } else if (beatCount >= 85 && beatCount < 95) {
      return "#FE740B";
    } else if (beatCount >= 40 && beatCount < 85) {
      return "#23AD23";
    }
    return "#000";
  }

  const officerUpdate = async () => {
    try {
      const result = await markOfficer(data.id);
      console.log(result);

      if (result?.data?.code === 200) {
        SuccessToast("Notification Status Updated");
        handleClose();
      }
    } catch (e) {
      console.error("Error:", e.response ? e.response.data : e.message);
    }
  };
  return (
    <div>
      <ModalContainer handleClose={handleClose}>
        <div
          className={`${styles.vitalsModal}`}
        >
          <div>
            <div className="d-flex justify-content-between">
            <div className="d-flex flex-grow-1 justify-content-center">
            <div className={styles.header}>
                  <ImageWithPlaceholder
                    src={data.image_url ? data.image_url : "/images/user-placeholder.jpeg"}
                    alt="Officer"
                    className={styles.profileImage}
                    placeholder={"/images/user-placeholder.jpeg"}
                  />
                  <div className={styles.userInfo}>
                    <h5 className="text-capitalize">{data.name}</h5>
                  </div>
                </div>
          </div>
          <p
            className={`${styles.xmark} bg-opacity-75 d-flex p-3 rounded-5`}
          >
            <FaXmark
              role="button"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </p>
            </div>
          <div className={styles.content}>
          <div className={styles.infoSection}>
              <div className={styles.infoRow}>
                <p>
                  <strong>Badge:</strong>
                </p>
                <p className="text-black">{data?.badge_no}</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Last Report:</strong>
                </p>
                <p className="text-black">{formatDateTime(data?.createdAt)}</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>HR:</strong>
                </p>
                <p>
                    <span className={beatColor(data?.heart_rate) === "#FF0000" ? styles.highLighted : beatColor(data?.heart_rate) === "#FE740B" ? styles.waiting : styles.connected }>
                      {data.heart_rate} bpm
                    </span>
                  </p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Temperature:</strong>
                </p>
                <p className="text-black">{officerlog?.data?.temperature|| '-'} F</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Motion:</strong>
                </p>
                <p className="text-black">{officerlog?.data?.motion|| '-'}</p>
              </div>
              <div className={styles.infoRow}>
                <p>
                  <strong>Perspiration:</strong>
                </p>
                <p className="text-black">{officerlog?.data?.prespiration|| '-'}</p>
              </div>
              {data?.send_help === 1 && (
              <div className={styles.infoRow}>
                <p>
                  <strong>Status:</strong>
                </p>
                <p className="text-black">
                  <span className={styles.waiting}>
                    Waiting for Officer Response
                  </span>
                </p>
              </div>
              )}
              <div className={styles.infoRow}>
                  <p>
                    <strong>Connection Status:</strong>
                  </p>
                  {data.status && (
                    <p className="text-black">
                      <span className={styles.connected}>Connected</span>
                    </p>
                  )}
                </div>
            </div>
          </div>
          <div className={styles.footer}>
            <button className={`${styles.officerOk} p-1 fs-7`} onClick={officerUpdate}>
              Officer is OK
            </button>
            <button className={`${styles.assistNow} p-1 fs-7`} onClick={() => {navigate('/chat'); dispatch(setOffersSlug(data))}}>
              Assist Now
            </button>
          </div>
          </div>
        </div>
      </ModalContainer>
    </div>
  );
};

export default AlertDispatcherModal;
