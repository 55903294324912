import React, { useEffect, useState } from "react";

import styles from "../../Pages/Dashboard/styles/Dashboard.module.scss";

import { Col, Row } from "react-bootstrap";
import { SubTitle } from "../../Components/CustomComponents";
import { BiSolidDroplet } from "react-icons/bi";
import { AiOutlineSearch } from "react-icons/ai";
import DashboardCard from "../../Components/Cards/DashboardCard";
import { useGetAllOfficersQuery } from "../../Data/services/officerApi";
import { useDispatch } from "react-redux";
import { setOffers } from "../../Data/features/officerSlice";

export default function DashboardLayout({ children }) {
  const [searchQuery, setSearchQuery] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10;
  const dispatch = useDispatch()
  const { data, error, isSuccess, isLoading, isFetching, refetch } = useGetAllOfficersQuery(
    {
      search: searchQuery.length >= 3 ? searchQuery : '',
      limit: pageSize,
      page: currentPage,
    },
    { refetchOnMountOrArgChange: true, pollingInterval: 8000, }
  );
  console.log(data, error);
  const filteredEmergencyDispatcher = data?.data?.filter(
    (item) => item.heart_rate >= 190
  );
  const filteredAlertDispatcher = data?.data?.filter(
    (item) => item.heart_rate > 150 && item.heart_rate < 190
  );
  const filteredNormalDispatcher = data?.data?.filter(
    (item) => item.heart_rate < 150 && item.heart_rate > 30
  );

  const handleSearchChange = (value) => {
    setSearchQuery(value);
  };


  useEffect(() => {
    // dispatch(setOffers(data?.data))
  },[data]);



  return (
    <div className={styles.Dashboard}>
      <Row className={`mb-4`}>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#BD4992FF"}
            bg2={"#F75DBE"}
            icon={<BiSolidDroplet color="#23AD23" />}
            number={filteredNormalDispatcher?.length}
            text={"Normal"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#BD4992FF"}
            bg2={"#F75DBE"}
            icon={<BiSolidDroplet color="#FE740B" />}
            number={filteredAlertDispatcher?.length}
            text={"Alert"}
          />
        </Col>
        <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6}>
          <DashboardCard
            bg1={"#BD4992FF"}
            bg2={"#F75DBE"}
            icon={<BiSolidDroplet color="#FF0000" />}
            number={filteredEmergencyDispatcher?.length}
            text={"Emergency"}
          />
        </Col>
      </Row>

      <div className={styles.headerContainer}>
        <div className={styles.titleContainer}>
          <SubTitle text={"Officers List"} />
        </div>
        <div className={styles.search}>
          <input
            type="text"
            placeholder="Search Officer"
            value={searchQuery}
            onChange={(e) => handleSearchChange(e.target.value)}
            />
          <div className={styles.searchButton}>
            <AiOutlineSearch color="#D2D2D2" />
          </div>
        </div>
      </div>
      <section className="mt-lg-4">
      {children}
      </section>
    </div>
  );
}
