import React, { useState } from 'react'
import DashboardLayout from '../../Components/Layouts/DashBoardLayout';
import { dispachterdata } from '../Dashboard';
import { CardTitle } from '../../Components/CustomComponents'
import DispatcherCard from '../../Components/Cards/DispatcherCard'
import { Col, Row } from 'react-bootstrap'
import VitalsModal from '../../Components/Modals/VitalModal';
import { useSelector } from 'react-redux';
import socketIo from "../../config/Socket";

const Emergency = () => {
const [vitalModal, setVitalModal] = useState(false)
const data = useSelector((state) => state.officerInfo);
const [modalData, setModalData] = useState({});
const [modaluserId, setMdoalUserId] = useState("");

const filteredEmergencyDispatcher = data?.data?.filter(
  (item) => item.heart_rate >= 100
) || [];


const handleModalClose = () => {
  setVitalModal(prev => !prev);
};

const handleResponse = (data, successMessage, errorMessage) => {
  setModalData(data);
  leaveRoom(modaluserId);
};

const joinRoom = (userId) => {
  socketIo.emit("_joinUserRoom", userId);
  socketIo.emit("_getOfficer", userId);
  socketIo.on("getOfficer_", (data) =>
    handleResponse(data, "", "Error getting officer details")
  );
};

const leaveRoom = (userId) => {
  socketIo.emit("_leaveUserRoom", userId);
};

  return (
    <DashboardLayout>
        <section>
        <CardTitle text='Emergency' classes="text-black fs-5 fw-bold mb-2"/>
        <Row className={`mb-5`}>
        {filteredEmergencyDispatcher.length > 0 ? (
            filteredEmergencyDispatcher.map((item) => (
                <Col className={`mt-2 mb-2`} lg={4} md={4} sm={6} key={item.id}                 
                onClick={() => {
                  setVitalModal(true);
                  joinRoom(item.slug);
                  setMdoalUserId(item.slug);
                }}>
                <DispatcherCard data={item} />
                </Col>
            ))
          ) : <p>Data Not Available</p>}
        </Row>
        </section>
        {vitalModal ? (
          <VitalsModal
            handleClose={handleModalClose}
            data={modalData}
          />
        ) : null}
    </DashboardLayout>
  )
}

export default Emergency;